#locationwrapper {
  .state-dropdown .p-dropdown {
    background: $white-color;
    border-radius: 30px;
    height: 2.3rem;
    width: 10rem;
    margin-right: 1.8rem;
  }
  .country-city-dropdown .p-dropdown {
    background: $white-color;
    border-radius: 30px;
    height: 2.3rem;
    width: 22rem;
    margin: 0.5rem 0 0.5rem 0;
  }

  .pi-chevron-down:before {
    left: 0 !important;
  }
}

p-dropdown.ng-dirty.ng-invalid > .p-dropdown {
  border-color: $continental-border-color;
}

.dropdown-wrapper .p-dropdown {
  background: $white-color;
  border-radius: 30px;
  height: 2.3rem;
  width: inherit;
  border-color: $continental-border-color;

  .pi-chevron-down:before {
    left: 0 !important;
  }
}
.p-dropdown .p-dropdown-trigger {
  color: $black-color;
}
.p-dropdown .p-dropdown-label.p-placeholder {
  color: $continental-border-color;
  padding-top: 0.3rem;
  font-weight: 400;
}
.p-dropdown-panel {
  ol,
  ul {
    padding-left: 0;
  }
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item,
.p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  color: $black-color;
}
.p-dropdown:not(.p-disabled):hover {
  border: 1px solid $continental-border-color;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background: $continental-yellow-color;
}
.p-dropdown .p-dropdown-panel {
  background: $white-color;
  border-radius: 17px;
}

.p-dropdown .p-dropdown-label {
  color: $black-color;
  padding-top: 0.3rem;
}

.p-dropdown .p-inputtext {
  color: $black-color;
}

//checkbox
.p-checkbox .p-checkbox-box {
  border: 1px solid $checkbox-border;
  background: transparent;
  height: 1rem;
  width: 1rem;
}
.user-archived {
  .p-checkbox .p-checkbox-box {
    width: 20px;
    height: 20px;
  }
}
.p-checkbox .p-checkbox-box.p-highlight,
.p-checkbox .p-checkbox-box.p-highlight:hover {
  border-color: $checkbox-border !important;
  background: $continental-yellow-color !important;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  border-color: $checkbox-border !important;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-empty-message {
  color: $black-color;
}

@media (max-width: 1380px) {
  .dropdown-wrapper .p-dropdown {
    width: 100%;
  }
}
