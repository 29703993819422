#asset-tab {
  .p-calendar .p-inputtext {
    height: 2.3rem;
    border-radius: 30px;
    width: 22rem;
    background-color: $white-color;
    color: $black-color;
    border: 1px solid $continental-border-color;
    border-top-right-radius: 30px !important;
    border-bottom-right-radius: 30px !important;
    &::-webkit-input-placeholder {
      color: #737373;
    }
  }
  .pi-chevron-right:before {
    position: unset;
    font-size: inherit;
  }

  .p-button {
    background: transparent;
    border: none;
  }
  .p-button.p-button-icon-only {
    position: relative;
    right: 3rem;
  }
  .p-datepicker:not(.p-datepicker-inline) {
    background: transparent;
    border: none;
  }
  .p-datepicker table td > span.p-highlight,
  .p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight,
  .p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
    background: $continental-yellow-color;
    color: $white-color;
  }
  .p-datepicker table td.p-datepicker-today > span {
    background: $white-color;
    color: $continental-yellow-color;
  }
  .p-datepicker table {
    background: $white-color;
    margin: 0;
    color: $black-color;
    border: 1px solid $continental-border-color;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
    border-spacing: 0;
    border-collapse: separate;
  }
  .p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
    background: $white-color;
    border: 1px solid $continental-border-color;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    width: 22rem;
  }
  .p-datepicker-group-container {
    width: 22rem;
  }
  .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year,
  .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month,
  .p-datepicker .p-datepicker-header .p-datepicker-prev,
  .p-datepicker .p-datepicker-header .p-datepicker-next,
  .p-datepicker-decade {
    color: $black-color;
  }
  .p-datepicker .p-monthpicker,
  .p-datepicker .p-yearpicker {
    margin: 0;
    background: $white-color;
    color: $black-color;
    border: 1px solid $continental-border-color;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
  }
  .p-button:enabled:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

.filter-date {
  .p-calendar .p-inputtext,
  .p-datepicker-group-container,
  .p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
    width: 17.9rem !important;
  }
}

table {
  th {
    > span {
      width: 2.5rem !important;
      height: 2.5rem !important;
    }
  }

  td {
    > span {
      width: 1.5rem !important;
      height: 1.5rem !important;
    }
  }
}

.p-datepicker .p-yearpicker .p-yearpicker-year:not(.p-disabled):focus,
.p-datepicker .p-monthpicker .p-monthpicker-month:not(.p-disabled):focus,
.p-datepicker table td.p-datepicker-today > span:not(.p-disabled):focus {
  outline: none !important;
  box-shadow: none !important;
}
@media (max-width: 1380px) {
  #asset-tab {
    .p-calendar .p-inputtext {
      width: 21rem;
    }
  }
  .filter-date {
    .p-calendar .p-inputtext,
    .p-datepicker-group-container,
    .p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
      width: 20.5rem !important;
    }
  }
}
