#wrapper .breadcrumClass .pi-chevron-right:before {
  position: relative !important;
  left: 0 !important;
  font-size: 13px;
}

#wrapper .p-breadcrumb ul li .p-menuitem-link {
  background-color: transparent !important;
  padding: 0;
  margin: 0;
}

.p-breadcrumb ul {
  align-items: baseline !important;
}

.p-breadcrumb ul li.p-breadcrumb-chevron {
  margin: 0 0.2rem 0 0.2rem;
}
