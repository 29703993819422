.user-password {
  position: relative;
  .p-password {
    width: inherit;
  }
  .p-inputtext {
    width: 100%;
    background: transparent;
    color: $black-color;
    border: none;
    padding: 0rem 2.5rem 0rem 0rem;
    width: 20rem;
    &::-webkit-input-placeholder {
      color: $continental-border-color;
    }
  }
  .p-input-icon-right > i:last-of-type {
    color: $black-color;
    left: 95%;
    cursor: pointer;
  }
  .p-inputtext:enabled:focus {
    box-shadow: none;
    border-color: transparent;
  }
  .p-password-panel {
    background: $white-color;
    color: $black-color;
    border-radius: 17px;
  }
}

@media (max-width: 1380px) {
  .user-password {
    .p-inputtext {
      width: 100%;
    }
  }
}
