/* You can add global styles to this file, and also import other style files */
/* Importing Bootstrap SCSS file. */
@use "@angular/material" as mat;

@import "variables";
@import "all";
@import "assets/fonts/materials-icons.css";
@import "assets/sass/datatable/paginator.scss";
@import "assets/sass/dropdown/dropdown.scss";
@import "assets/sass/tab/tab.scss";
@import "assets/sass/dialog/dialog.scss";
@import "assets/sass/breadcrumb/breadcrumb.scss";
@import "assets/sass/datepicker/datepicker.scss";
@import "assets/sass/auto-complete/auto-complete.scss";
@import "assets/sass/password/password.scss";

$theme: mat.define-theme(
  (
    color: (
      theme-type: light,
      primary: mat.$orange-palette,
    ),
    typography: (
      brand-family: "Nunito Sans",
      bold-weight: 700,
    ),
    density: (
      scale: -3,
    ),
  )
);

html,
body {
  height: 100%;
  background-color: #e5e5e5;
}
body {
  @include mat.all-component-themes($theme);
  --mat-form-field-error-text-color: #f44336;
  margin: 0;
  font-family: "Nunito sans";
}

@include mat.core();
@include mat.color-variants-backwards-compatibility($theme);

.mat-mdc-text-field-wrapper {
  background-color: $white-color;
}
.mdc-text-field {
  border-bottom-right-radius: var(
    --mdc-outlined-text-field-container-shape
  ) !important;
  border-bottom-left-radius: var(
    --mdc-outlined-text-field-container-shape
  ) !important;
}
.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper {
  padding: 0 !important;
}
.mat-mdc-form-field-bottom-align::before {
  content: none !important;
}
