/* SCSS Variables */

/** continental colors ***/
$white-color: #fff;
$black-color: #000;
$continental-yellow-color: #ffa500;
$carousel-inactive-color: #8d8d8d;
$continental-text-color: #222222;
$continental-body-color: #eaeaea;
$continental-border-color: #737373;
$continental-truck-border-color: #080808;
$continental-bread-crumb-color: #969696;
$continental-red-color: #ff2d37;
$continental-blue-color: #7983ea;
$payload-color: #2db928;
$payload-remaining-color: #c3c3c3;
$table-header-bg-color: #f7f7f7;
$light-black-color: #1a1a1a;
$input-error-color: #8d8d8d;
$profile-heading: #707070;
$error-color: #f44336;
$dark-black-color: #333333;
$green-color: #008000;
$light-grey-color: #9b9b9b;
$menu-border: #979797;
$continental-chart-link: #00a5dc;
$device-count: #dc8f00;
$chart-title-color: #666666;
$device-dashboard-color: #999999;
$table-block-color: #333333;
$footer-color: #cdcdcd;
$label-color: #515c66;
$cancel-btn-color: #febb40;
$checkbox-border: #949494;
$tab-bottom: #f0f0f0;
$link-color: #00a5dc;
$table-head-color: #cdcdcd;
$dropdown-link-hover-bg: #e9ecef;

//paginator
$paginatorBg: #fdfeff !default;
$paginatorTextColor: #515c66 !default;
$paginatorBorder: solid rgba(#e8f1f8, 0.7) !default;
$paginatorBorderWidth: 0 !default;
$paginatorPadding: 0.5rem 1rem !default;
$paginatorElementWidth: 2.357rem !default;
$paginatorElementHeight: 2.357rem !default;
$paginatorElementBg: transparent !default;
$paginatorElementBorder: 0 none !default;
$paginatorElementIconColor: #515c66 !default;
$paginatorElementHoverBg: rgba(#e8f1f8, 0.7) !default;
$paginatorElementHoverBorderColor: transparent !default;
$paginatorElementIconHoverColor: #515c66 !default;
$paginatorElementBorderRadius: 50% !default;
$paginatorElementMargin: 0.143rem !default;
$paginatorElementPadding: 0 !default;
