.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link,
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link:hover {
  color: $continental-yellow-color !important;
  border-bottom: 2px solid $continental-yellow-color !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  padding: 0.75rem 2rem;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link:hover {
  background: transparent !important;
  color: $black-color !important;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link,
.p-tabview .p-tabview-nav li,
.p-tabview .p-tabview-nav li:hover {
  background: transparent !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1.3px solid $continental-bread-crumb-color !important;
  color: $black-color !important;
}
.p-tabview .p-tabview-panels {
  background: transparent;
  color: $black-color;
  padding-left: 0;
  padding-right: 0;
}
.p-tabview .p-tabview-nav {
  border: none;
}
.p-tabview-nav-container {
  top: 1rem;
}
.p-tabview-title {
  font-weight: 400;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  outline: none !important;
  box-shadow: none !important;
}

#asset-tab .p-tabview .p-tabview-nav li .p-tabview-nav-link {
  display: flex;
  justify-content: center;
  width: 15rem;
}
