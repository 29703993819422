.p-dialog .p-dialog-header {
  border-bottom: 1px solid $continental-border-color;
  background: $white-color;
  color: $black-color;
}
.p-dialog .p-dialog-header .p-dialog-header-icon {
  color: $black-color;

  &:hover,
  :focus {
    color: $black-color !important;
    border: none !important;
  }
}
.p-dialog .p-dialog-content {
  background: $white-color;
  color: $black-color;
}
