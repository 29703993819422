.p-autocomplete-panel {
  ul,
  ol {
    padding-left: 0;
  }
  background-color: $white-color;
  border: 1px solid $continental-border-color;
  border-radius: 17px;
  box-shadow: none;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item,
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:hover {
  color: $black-color;
  white-space: normal;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
  background: $continental-yellow-color;
}

#autocomplete-partner-search {
  height: 2.3rem;
  padding: 0;
  border-radius: 30px;
  border: none;
  .p-inputtext {
    height: 2.3rem;
    padding: 0 0.8rem 0 2.5rem;
    border-radius: 30px;
    width: 25rem;
    background-color: $white-color;
    color: $black-color;
    border: 1px solid $continental-border-color;
    &::-webkit-input-placeholder {
      color: #737373;
    }
  }
}
.autocomplete-asset-search {
  height: 2.3rem;
  padding: 0;
  border-radius: 30px;
  border: none;
  .p-inputtext {
    height: 2.3rem;
    padding: 0 0.8rem 0 2.5rem;
    border-radius: 30px;
    width: 22rem;
    background-color: $white-color;
    color: $black-color;
    border: 1px solid $continental-border-color;
    &::-webkit-input-placeholder {
      color: #737373;
    }
    &:enabled:focus {
      border-color: $continental-border-color;
    }
  }
}

.autocomplete-attribute-search {
  height: 2.3rem;
  padding: 0;
  border-radius: 30px;
  border: none;
  .p-inputtext {
    height: 2.3rem;
    padding: 0 0.8rem 0 2.5rem;
    border-radius: 30px;
    width: 41rem;
    background-color: $white-color;
    color: $black-color;
    border: 1px solid $continental-border-color;
    &::-webkit-input-placeholder {
      color: #737373;
    }
    &:enabled:focus {
      border-color: $continental-border-color;
    }
  }
}

.filter-assets .p-inputtext {
  width: 20rem;
}

.attribute-partner .p-inputtext {
  width: 16rem;
}

@media (max-width: 1380px) {
  .autocomplete-asset-search,
  .autocomplete-attribute-search {
    .p-inputtext {
      width: 100%;
    }
  }
}
