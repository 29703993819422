.p-paginator {
  background: transparent;
  color: $paginatorTextColor;
  border: $paginatorBorder;
  border-width: $paginatorBorderWidth;
  padding: $paginatorPadding;
  border-radius: 3px;
  border: none;

  .p-paginator-first,
  .p-paginator-prev,
  .p-paginator-next,
  .p-paginator-last {
    background-color: $paginatorElementBg;
    border: $paginatorElementBorder;
    color: $paginatorElementIconColor;
    min-width: $paginatorElementWidth;
    height: $paginatorElementHeight;
    margin: $paginatorElementMargin;
    transition: box-shadow 0.2s;
    border-radius: $paginatorElementBorderRadius;

    &:not(.p-disabled):not(.p-highlight):hover {
      background: $paginatorElementHoverBg;
      border-color: $paginatorElementHoverBorderColor;
      color: $paginatorElementIconHoverColor;
    }
  }

  .p-paginator-first {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  .p-paginator-last {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  .p-dropdown {
    margin-left: 8rem;
    background: $white-color;
    border-radius: 25px;
    height: $paginatorElementHeight;

    .p-dropdown-label {
      padding-right: 0;
      color: $label-color;
    }
    .p-dropdown-trigger {
      color: $black-color;
    }

    .p-dropdown-panel {
      background: $white-color;

      .p-dropdown-items .p-dropdown-item,
      .p-dropdown-items .p-dropdown-item:hover,
      .p-dropdown-items .p-dropdown-item:active {
        color: $black-color;
      }
      .p-dropdown-items .p-dropdown-item.p-highlight {
        background: $continental-yellow-color;
      }
    }
  }

  .p-paginator-current {
    background-color: $paginatorElementBg;
    border: $paginatorElementBorder;
    color: $paginatorElementIconHoverColor;
    min-width: $paginatorElementWidth;
    height: $paginatorElementHeight;
    margin: $paginatorElementMargin;
    padding: 0 0.5rem;
  }

  .p-paginator-pages {
    .p-paginator-page {
      background-color: $paginatorElementBg;
      border: $paginatorElementBorder;
      color: $paginatorElementIconColor;
      min-width: $paginatorElementWidth;
      height: $paginatorElementHeight;
      margin: $paginatorElementMargin;
      transition: box-shadow 0.2s;
      border-radius: $paginatorElementBorderRadius;

      &.p-highlight {
        background: $continental-yellow-color;
        border-color: $continental-yellow-color;
        color: $white-color;
        border-radius: $paginatorElementBorderRadius;
      }

      &:not(.p-highlight):hover {
        background: $paginatorElementHoverBg;
        border-color: $paginatorElementHoverBorderColor;
        color: $paginatorElementIconHoverColor;
      }
    }
  }
  .pi-chevron-down:before {
    left: 0 !important;
  }
}
.p-datatable .p-datatable-thead > tr > th,
.p-datatable .p-datatable-thead > tr > th:hover,
.p-datatable .p-sortable-column:not(.p-highlight):hover,
.p-datatable .p-sortable-column.p-highlight,
.p-datatable .p-sortable-column.p-highlight:hover {
  border: none;
  background: $table-head-color;
  color: $black-color;
  width: 120px;
}
.p-datatable .p-sortable-column:focus {
  box-shadow: none;
  outline: none;
  color: $black-color;
}
.p-datatable .p-datatable-tbody > tr {
  background: $white-color;
  color: $black-color;
}
.p-datatable .p-datatable-tbody > tr > td {
  border: 1px solid $tab-bottom;
  border-width: 1px 0 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 120px;
}
.p-datatable .p-sortable-column .p-sortable-column-icon,
.p-datatable .p-sortable-column .p-sortable-column-icon:hover,
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon,
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon:hover {
  margin-left: 0;
  color: $black-color !important;
}
#wrapper .p-menuitem-link-active {
  background-color: #ffa500 !important;
  margin: 0 0 0 -21px;
  padding-left: 38px;
}
#wrapper .p-datatable > .p-datatable-wrapper {
  border-radius: 0.5rem;
  box-shadow: 0px 4px 5px rgb(0 0 0 / 25%);
}

.p-dropdown:not(.p-disabled).p-focus {
  border-color: $checkbox-border;
}

.p-paginator-rpp-options::before {
  position: absolute;
  right: 5rem;
  top: 0.5rem;
  font-size: 14px;
  content: "Show";
}
.pi-sort-alt:before {
  content: url(../../images/table-sort-icon.svg) "\a";
}
