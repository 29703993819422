/*Global Styles*/
html,
body {
  height: 100%;
  background-color: $white-color;
  font-family: "Nunito Sans" !important;
}

a:focus {
  cursor: pointer;
}

ul,
ul li {
  list-style: none;
}

*:focus {
  outline: none;
}

.mat-card {
  font-family: "Nunito Sans" !important;
}

body {
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-family: "Nunito Sans";
  // color: $module-fill-color;
  padding: 0px;
  margin: 0px;
  margin: 0 auto;
  /* hide scrollbar but allow scrolling */
  scrollbar-width: none !important; /* for Firefox */
}
/* hide scrollbar but allow scrolling */
body::-webkit-scrollbar {
  display: none !important; /* for Chrome, Safari, and Opera */
}

a {
  cursor: pointer !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.p-breadcrumb {
  homeicon,
  chevronrighticon {
    display: none;
  }
  .p-menuitem-separator::before {
    color: $black-color;
    content: "/";
    font-weight: 700;
  }
  .p-breadcrumb-list li:last-child .p-menuitem-text {
    color: $black-color;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: inherit;
  top: inherit;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner 0.8s linear infinite;
  color: #fff;
  opacity: 0.5;
  width: 20px;
  height: 20px;
}

.ngx-datatable.material .datatable-header {
  color: #000 !important;
}

.ngx-datatable.material {
  width: 100% !important;
}
.ngx-datatable.material .datatable-header,
.ngx-datatable.scroll-horz .datatable-body,
.ngx-datatable .datatable-body .datatable-scroll,
.ngx-datatable.material .datatable-body .datatable-body-row.datatable-row-even,
.ngx-datatable .datatable-body .datatable-row-wrapper {
  width: 100% !important;
  background: #cdcdcd;
}

.ngx-datatable.scroll-horz .datatable-body,
.ngx-datatable .datatable-body .datatable-scroll,
.ngx-datatable.material .datatable-body .datatable-body-row.datatable-row-even,
.ngx-datatable .datatable-body .datatable-row-wrapper {
  background: #fff !important;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}

.datatable-row-even {
  background-color: #fff;
}
.datatable-row-odd {
  background-color: #fff;
}
.datatable-body {
  .datatable-row-left {
    background-color: inherit;
  }
}

/*** reset password page ****/

.otp .mat-form-field-subscript-wrapper {
  margin-top: 12px !important;
}

/****/

.login-row .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 0 !important;
}

.login-row .mat-error {
  padding-top: 5px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
input::-webkit-input-placeholder {
  color: $black-color;
}

a {
  color: $link-color;
  text-decoration: none;
}

.delete-btn {
  color: $black-color;
  height: 2.4rem;
  border-radius: 19px;
  background-color: $white-color;
  border: 1px solid $continental-yellow-color;
  width: 9.75rem;
  display: inline-block;
  position: relative;
  bottom: 0.5rem;
  line-height: normal;
  font-weight: 600;
}
article {
  background-color: $white-color;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 1rem;
  position: relative;
}

.form-control:disabled {
  background-color: $white-color;
  opacity: 0.65;
}

.invalid-btn:disabled {
  background-color: $footer-color !important;
  color: $continental-bread-crumb-color !important;
  cursor: not-allowed !important;
}

.warning-layer::-webkit-scrollbar {
  width: 6px; /* Adjust as needed */
}

.warning-layer::-webkit-scrollbar-thumb {
  background-color: transparent; /* Hide scrollbar thumb */
}

.warning-layer::-webkit-scrollbar-track {
  background-color: transparent; /* Hide scrollbar track */
}
